import { atom } from "nanostores";
import {
  listNotificationsAsync,
  markAllNotificationsAsReadAsync,
  markAllNotificationsAsUnreadAsync,
  markNotificationAsReadAsync,
} from "../api/rest";
import { Notification } from "../api/models";

export const items = atom([] as Notification[]);
export const initialItems = atom([] as Notification[]);
export const unreadCount = atom(0);
export const currentPage = atom(1);
export const nextPage = atom(1);
export const hasMore = atom(true as boolean);
export const count = atom(0);

const pageSize = 10;
let queue = Promise.resolve(); // Queue initialized as a resolved Promise

export function getNotifications(fetchMore = false) {
  // Chain each call to `queue`, ensuring they execute sequentially
  queue = queue.then(() => fetchNotifications(fetchMore));
}

function areArraysEqual<T>(arr1: T[], arr2: T[]): boolean {
  if (arr1.length !== arr2.length) return false;
  return arr1.every(
    (item, index) => JSON.stringify(item) === JSON.stringify(arr2[index]),
  );
}

export async function fetchNotifications(
  fetchMore: boolean = false, // will default to false on the first call
) {
  if (!fetchMore) {
    currentPage.set(1); // reset the page number when fetching initial data
    nextPage.set(1); // reset the next page number when fetching
  }
  const response = await listNotificationsAsync({
    page: nextPage.get(),
    page_size: pageSize,
  });
  if (response.success) {
    // don't update the store if the data is the same
    // this avoids the double loading effect when initial load may rerender
    if (
      !fetchMore &&
      areArraysEqual(response.data.results, initialItems.get())
    ) {
      return;
      // otherwise, if initial fetch, set the results which will overwrite the store
    } else if (!fetchMore) {
      items.set(response.data.results);
      initialItems.set(response.data.results); // and update the initial items again
      // lastly, if fetch more, append the results to the existing store
    } else if (fetchMore) {
      items.set([...items.get(), ...response.data.results]);
    }
    unreadCount.set(response.data.unread_count);
    count.set(response.data.count);
    hasMore.set(response.data.next !== null);
    currentPage.set(nextPage.get());
  }
}

export function getMoreNotifications() {
  if (!hasMore.get()) {
    return;
  }
  nextPage.set(currentPage.get() + 1);
  getNotifications(true);
}

export async function markNotificationAsRead(id: number) {
  const response = await markNotificationAsReadAsync(id);
  if (response.success) {
    unreadCount.set(unreadCount.get() - 1);
  }
}

export async function markAllNotificationsAsRead() {
  const response = await markAllNotificationsAsReadAsync();
  if (response.success) {
    getNotifications(); // fetch the notifications again to get the updated unread count
  }
}

export async function markAllNotificationsAsUnread() {
  const response = await markAllNotificationsAsUnreadAsync();
  if (response.success) {
    getNotifications(); // fetch the notifications again to get the updated unread count
  }
}

