//src/pages/NotificationsPage.jsx

import { NotificationsContainer } from "../components/Notifications/NotificationsContainer";

const NotificationsPage = () => {
  return (
    <div className="main-layout">
      <div className="notifications-page-layout">
        <NotificationsContainer />
      </div>
    </div>
  );
};

export default NotificationsPage;

