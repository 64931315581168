import { useEffect, useState } from "react";
import LoadingIndicatorBars from "../Utils/LoadingIndicatorBars.jsx";
import SearchField from "./FormFields/SearchField.jsx";
import { useStore } from "@nanostores/react";
import {
  inviteUserModalOpenStore,
  isModalLoadingStore,
  closeModals,
} from "../../stores/modalStore.js";
import { DangerAlert } from "../Utils/Alerts.jsx";
import { listCollectiveMembers } from "../../services/Collectives/listCollectiveMembers.js";
import { editCollectiveMembers } from "../../services/Collectives/editCollectiveMembers.js";
import { collectiveStore, getCollective } from "../../stores/collectiveStore";

const NewParentPostForm = () => {
  // loading states for managing the initial users and the submit button
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const activeCollectiveId = useStore(collectiveStore)?.id;
  const activeProjectId = useStore(collectiveStore)?.project?.id;
  // set the initial collective members for monitoring changes
  const [initialCollectiveMembers, setInitialCollectiveMembers] = useState([]);
  // set the selected users for passing to the search field
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  // states for managing the messages
  const [dangerMessage, setDangerMessage] = useState("");

  // fetch the collective members and set as selected users when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      setIsFetchLoading(true);
      const response = await listCollectiveMembers(activeCollectiveId);
      // set the colletive members if there is a response
      if (response && Array.isArray(response)) {
        setInitialCollectiveMembers(response);
        setSelectedUsers(response);
      }
      setIsFetchLoading(false);
    };

    fetchData();
  }, [activeCollectiveId]);

  // determine if the submit button should be disabled
  useEffect(() => {
    const initialMembersIds = initialCollectiveMembers
      .map((member) => member.id)
      .sort();
    const selectedUsersIds = selectedUsers.map((user) => user.id).sort();

    if (
      JSON.stringify(initialMembersIds) !== JSON.stringify(selectedUsersIds)
    ) {
      setIsSubmitButtonDisabled(false);
    } else {
      setIsSubmitButtonDisabled(true);
    }
  }, [selectedUsers, initialCollectiveMembers]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    isModalLoadingStore.set(true);
    setDangerMessage("");

    // set the selected user ids for the API call
    const selectedUserIds = selectedUsers.map((user) => user.id);

    // Call API and await messages
    const response = await editCollectiveMembers(
      activeCollectiveId,
      selectedUserIds,
    );

    if (response.successMessage) {
      closeModals();
      await getCollective({
        collectiveId: activeCollectiveId,
        projectId: activeProjectId,
      });
      setIsLoading(false);
    } else {
      // Handle error messages
      setDangerMessage(response.dangerMessage);
      isModalLoadingStore.set(false);
      setIsLoading(false);
    }
  };

  if (isFetchLoading) {
    return (
      <div style={{ height: "3rem", color: "var(--primary-color)" }}>
        <LoadingIndicatorBars />
      </div>
    );
  }

  const handleInviteUser = () => {
    inviteUserModalOpenStore.set(true);
  };

  return (
    <div>
      <form className="form-container" onSubmit={handleSubmit}>
        <p className=" text-muted">
          Search current users or{" "}
          <span
            className="text-accented"
            style={{ cursor: "pointer", fontWeight: "500" }}
            onClick={handleInviteUser}
          >
            Invite New User
          </span>
        </p>
        <SearchField
          isLoading={isLoading}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
        />
        <div>
          {isLoading ? (
            <div style={{ height: "3rem", color: "var(--primary-color)" }}>
              <LoadingIndicatorBars />
            </div>
          ) : (
            <button
              style={{ width: "100%" }}
              className="btn-primary"
              type="submit"
              disabled={isSubmitButtonDisabled || isLoading}
            >
              <h3>Save Members</h3>
            </button>
          )}
        </div>
        <DangerAlert message={dangerMessage} />
      </form>
    </div>
  );
};

export default NewParentPostForm;

