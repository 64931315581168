//src/App.js

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PublicLayout from "./layouts/PublicLayout";
import ProtectedLayout from "./layouts/ProtectedLayout";
import DefaultPage from "./pages/DefaultPage";
import HomePage from "./pages/HomePage";
import RegisterPage from "./pages/RegisterPage";
import PasswordResetRequestPage from "./pages/PasswordResetRequestPage";
import PasswordResetConfirmPage from "./pages/PasswordResetConfirmPage";
import InviteNewUserPage from "./pages/InviteNewUserPage";
import CollectivePage from "./pages/CollectivePage";
import NotificationsPage from "./pages/NotificationsPage";
import FilePage from "./pages/FilePage";

// MODALS
import CreateCollectiveModal from "./components/Modals/CreateCollectiveModal";
import CreateParentPostModal from "./components/Modals/CreateParentPostModal";
import CreateProjectModal from "./components/Modals/CreateProjectModal";
import EditCollectiveMembersModal from "./components/Modals/EditCollectiveMembersModal";
import EditProfileModal from "./components/Modals/EditProfileModal";
import InviteNewUserModal from "./components/Modals/InviteNewUserModal";
import ViewUserDetailModal from "./components/Modals/ViewUserDetailModal";

function App() {
  return (
    <Router>
      {/* MODALS */}
      <CreateCollectiveModal />
      <CreateParentPostModal />
      <CreateProjectModal />
      <EditCollectiveMembersModal />
      <EditProfileModal />
      <InviteNewUserModal />
      <ViewUserDetailModal />
      {/* ROUTES */}
      <Routes>
        {/* PUBLIC ROUTES */}
        <Route
          exact
          path="*"
          element={
            <PublicLayout>
              <DefaultPage />
            </PublicLayout>
          }
        />
        <Route
          exact
          path="/"
          element={
            <PublicLayout>
              <HomePage />
            </PublicLayout>
          }
        />
        <Route
          exact
          path="/register/*"
          element={
            <PublicLayout>
              <RegisterPage />
            </PublicLayout>
          }
        />
        <Route
          exact
          path="/password-reset"
          element={
            <PublicLayout>
              <PasswordResetRequestPage />
            </PublicLayout>
          }
        />
        <Route
          exact
          path="/password-reset-confirm/:uid/:token"
          element={
            <PublicLayout>
              <PasswordResetConfirmPage />
            </PublicLayout>
          }
        />

        {/* AUTHENTICATED ROUTES */}
        <Route
          path="/studio/*"
          element={
            <ProtectedLayout>
              <DefaultPage />
            </ProtectedLayout>
          }
        />
        <Route
          path="/studio/collective/:collectiveId/*"
          element={
            <ProtectedLayout>
              <CollectivePage />
            </ProtectedLayout>
          }
        ></Route>
        {/* This route is used for redirecting if no Collective ID is provided */}
        <Route
          path="/studio/collective/*"
          element={
            <ProtectedLayout>
              <CollectivePage />
            </ProtectedLayout>
          }
        ></Route>
        <Route
          path="/studio/file/*"
          element={
            <ProtectedLayout>
              <FilePage />
            </ProtectedLayout>
          }
        />
        <Route
          path="/studio/invite-new-user"
          element={
            <ProtectedLayout>
              <InviteNewUserPage />
            </ProtectedLayout>
          }
        />
        <Route
          path="/studio/notifications"
          element={
            <ProtectedLayout>
              <NotificationsPage />
            </ProtectedLayout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;

