import axios, { AxiosResponse, AxiosError } from "axios";
import { refreshToken } from "../services/Auth/refreshToken.js";
import { ApiResponse, IResponseErrors } from "./generics.js";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const axiosClient = axios.create({
  baseURL: BACKEND_URL + "/api",
});

// automate the process of adding the access token to the request headers
axiosClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error),
);

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    // Formats all successful responses into the standard format
    // A hack to make sure we're not nesting data.data inside a retry request.
    // And ensuring that validate responses without data are still success
    if (response.data && !("data" in response.data)) {
      response.data = { success: true, data: response.data, errors: null };
    } else if (!response.data && response.status < 400) {
      response.data = { success: true, data: null, errors: null };
    }
    return response;
  },
  // handles refreshing the token if it's expired
  async (error) => {
    if (
      error.response?.status === 401 &&
      error.config &&
      !error.config._retry
    ) {
      error.config._retry = true;
      try {
        await refreshToken();
        error.config.headers["Authorization"] =
          "Bearer " + localStorage.getItem("accessToken");
        return axiosClient(error.config); // Retry the original request
      } catch (_) {
        // If the refresh fails, reject the error
        return Promise.reject(error);
      }
    }
    return Promise.reject(error); // For other errors, reject the error as is
  },
);

/**
 * Helper function to convert 400s details into usable types.
 */
function formatBadRequestData(
  response: AxiosResponse<any, any>,
): ApiResponse<any> {
  if (response.data["detail"] !== undefined) {
    return {
      success: false,
      data: null,
      errors: { detail: response.data["detail"], fields: {} },
    };
  } else {
    return {
      success: false,
      data: null,
      errors: { detail: "Bad Request", fields: response.data },
    };
  }
}

/**
 * Handles transforming Django API error responses into status format
 */
export const errorHandler = (error: AxiosError): ApiResponse => {
  if (error.response) {
    if (error.response.status === 400) {
      return formatBadRequestData(error.response);
    } else if (error.response.status >= 401 && error.response.status < 500) {
      return {
        success: false,
        data: null,
        errors: error.response.data as IResponseErrors,
      };
    } else if (error.response.status === 503) {
      return {
        success: false,
        data: null,
        errors: { detail: "Please try again later", fields: {} },
      };
    } else if (error.response.status >= 500) {
      return {
        success: false,
        data: null,
        errors: { detail: "Server Error", fields: {} },
      };
    } else {
      return {
        success: false,
        data: null,
        errors: { detail: "Unknown error occurred", fields: {} },
      };
    }
  } else {
    console.error("Cannot connect to API");
    return {
      success: false,
      data: null,
      errors: { detail: "Cannot connect to API", fields: {} },
    };
  }
};

