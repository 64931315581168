import { useEffect, useState } from "react";
import LoadingIndicatorBars from "../Utils/LoadingIndicatorBars.jsx";
import TextInputField from "./FormFields/TextInputField.jsx";
import { useStore } from "@nanostores/react";
import { setActivePostId } from "../../stores/postDetailStore.js";
import { isModalLoadingStore, closeModals } from "../../stores/modalStore.js";
import { addPost } from "../../services/Posts/addPost.js";
import { useDropzone } from "react-dropzone";
import { WarningAlert, DangerAlert } from "../Utils/Alerts.jsx";
import RichTextEditor from "./FormFields/RichTextEditor.jsx";
import { collectiveStore, getCollective } from "../../stores/collectiveStore";

const NewParentPostForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const collective = useStore(collectiveStore);
  const collectiveId = collective?.id;
  const activeCollectiveTitle = collective?.title;
  const activeProjectId = collective?.project?.id;
  const [title, setTitle] = useState("");
  const [richTextJson, setRichTextJson] = useState(null);
  const [mentionedUserIds, setMentionedUserIds] = useState([]);
  const [mentionedPostIds, setMentionedPostIds] = useState([]);
  const [comment, setComment] = useState("");
  const [isPostButtonDisabled, setIsPostButtonDisabled] = useState(true);
  const [files, setFiles] = useState([]);
  const parentPostId = null;
  const currentTrackTimestamp = undefined;
  const relatedPostContentId = undefined;

  // states for managing the messages
  const [warningMessage, setWarningMessage] = useState("");
  const [dangerMessage, setDangerMessage] = useState("");

  // determine if the post button should be disabled
  useEffect(() => {
    if (title && comment) {
      setIsPostButtonDisabled(false);
    } else {
      setIsPostButtonDisabled(true);
    }
  }, [title, comment]);

  // drag and drop file functionality setup
  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: (uploadedFiles) => {
      if (uploadedFiles.length > 1) {
        // Warn the user if they try to drop more than one file
        setWarningMessage("Only a single file can be added to a parent post.");
        setTimeout(() => setWarningMessage(null), 2000); // Clear the message after 2 seconds
      } else if (uploadedFiles.length === 1) {
        if (files.length === 0) {
          // Set the file only if there are no files already set
          setFiles([uploadedFiles[0]]);
        } else {
          // If a file is already set, warn the user and do not add new files
          setWarningMessage(
            "Only a single file can be added to a parent post. Remove the existing file to add a new one.",
          );
          setTimeout(() => setWarningMessage(null), 2000); // Clear the message after 2 seconds
        }
      }
    },
    onDragOver: (event) => {
      event.preventDefault(); // This is important to prevent the browser's default handling of the data
      if (event.dataTransfer.items.length > 1) {
        setWarningMessage("Only a single file can be added to a parent post.");
        setTimeout(() => setWarningMessage(null), 2000); // Clear the message after 2 seconds
      }
    },
    noClick: true, // Prevents the dropzone from opening the file dialog when clicked
    multiple: false, // Allows only one file to be dropped at a time
  });

  const handlePostSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    isModalLoadingStore.set(true);
    setWarningMessage("");
    setDangerMessage("");

    const response = await addPost(
      parentPostId,
      collectiveId,
      title,
      comment,
      files,
      richTextJson,
      mentionedUserIds,
      mentionedPostIds,
      currentTrackTimestamp,
      relatedPostContentId,
    );

    if (response.successMessage) {
      closeModals();
      await getCollective({
        collectiveId: collectiveId,
        projectId: activeProjectId,
      });
      setActivePostId(response.newPostId);
      setIsLoading(false);
    } else {
      // Handle error messages
      setWarningMessage(response.warningMessage);
      setDangerMessage(response.dangerMessage);
      isModalLoadingStore.set(false);
      setIsLoading(false);
    }
  };

  const handleFileRemove = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };

  const handleAddFiles = (e) => {
    e.stopPropagation(); // Prevent the dropzone from being triggered
    open(); // open the file dialog
  };

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} style={{ display: "none" }} />
      <form className="form-container" onSubmit={handlePostSubmit}>
        <h3>
          Posting within{" "}
          <span className="text-accented">{activeCollectiveTitle}</span>
        </h3>
        <TextInputField
          initialValue={title}
          setValue={setTitle}
          isLoading={isLoading}
          placeholder={
            "Provide a concise title for this song, idea, or concept."
          }
        />
        <div
          className={`new-post-form-description ${
            isLoading ? "loading-field" : ""
          }`}
        >
          <RichTextEditor
            initialJson={richTextJson}
            initialText={comment}
            setJson={setRichTextJson}
            setText={setComment}
            setMentionedUserIds={setMentionedUserIds}
            setMentionedPostIds={setMentionedPostIds}
            placeholderValue={
              "What do you want others to know about this song, idea, or concept? What inspired it? Is there a story behind it? How do you want others to engage with it?"
            }
          />
        </div>
        <div
          {...getRootProps()}
          className={`new-post-form-files-container ${
            isDragActive ? "drag-active" : ""
          }`}
        >
          {files.length === 0 && (
            <p className="text-accented" onClick={handleAddFiles}>
              Drag and drop file here or click to add
            </p>
          )}
          {files.length > 0 && (
            <div>
              {files.map((file, index) => {
                const fileNameParts = file.name.split(".");
                const extension = fileNameParts.pop();
                const fileNameWithoutExtension = fileNameParts.join(".");

                return (
                  <div key={index} className="attachment-object preview-lg">
                    <p className="text-overflow-trim short">
                      {fileNameWithoutExtension +
                        (extension ? "." + extension : "")}
                    </p>
                    <i
                      onClick={() => handleFileRemove(file.name)}
                      className="bi bi-trash-fill attachment-remove"
                    ></i>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div>
          {isLoading ? (
            <div style={{ height: "3rem", color: "var(--primary-color)" }}>
              <LoadingIndicatorBars />
            </div>
          ) : (
            <button
              style={{ width: "100%" }}
              className="btn-primary"
              type="submit"
              disabled={isPostButtonDisabled || isLoading}
            >
              <h3>Create Post</h3>
            </button>
          )}
        </div>
        <WarningAlert message={warningMessage} />
        <DangerAlert message={dangerMessage} />
      </form>
    </div>
  );
};

export default NewParentPostForm;

