//src/components/DetailArea/CollectiveDetail/CollectiveDetail.jsx

import { CardSection } from "../../Utils/CustomCard";
import { useStore } from "@nanostores/react";
import HeaderCard from "./Components/HeaderCard";
import ProjectListCard from "./Components/ProjectListCard";
import MembersCard from "./Components/MembersCard";
import PendingInvitesCard from "./Components/PendingInvitesCard";
import { userIsAdminOwnerOrMember } from "../../Utils/permissionsUtils";
import { editCollectiveModalOpenStore } from "../../../stores/modalStore";
import {
  collectiveStore,
  collectiveLoadingStore,
} from "../../../stores/collectiveStore";

function CollectiveDetail() {
  const collective = useStore(collectiveStore);
  const isLoading = useStore(collectiveLoadingStore);
  const currentUserRole = collective?.user_role;
  const userCanEdit = userIsAdminOwnerOrMember(currentUserRole);
  const projectList = collective?.project_list;

  const handleEditCollectiveClick = () => {
    editCollectiveModalOpenStore.set(true);
  };

  return (
    <div className="detail-area">
      <CardSection>
        <HeaderCard
          headerTitle={collective?.title}
          headerSubtitle={"Collective"}
          descriptionTitle={"Collective Description"}
          description={collective?.description}
          image={collective?.collective_image}
          isLoading={isLoading}
          isEditable={userCanEdit}
          onEditClick={handleEditCollectiveClick}
        />
        <ProjectListCard
          title={"Collective Projects"}
          projectList={collective?.project_list}
          collectiveId={collective?.id}
          isLoading={isLoading}
          isHidden={!userCanEdit && !projectList?.length > 0}
          isEditable={userCanEdit}
        />
        <MembersCard
          title={"Collective Members"}
          members={collective?.members}
          isLoading={isLoading}
          isEditable={userCanEdit}
        />
        <PendingInvitesCard
          title={"Pending Collective Invitations"}
          pendingInvites={collective?.pending_invites}
          isLoading={isLoading}
          isEditable={userCanEdit}
          isHidden={!userCanEdit}
        />
      </CardSection>
    </div>
  );
}

export default CollectiveDetail;

