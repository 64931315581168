//src/components/DetailArea/CollectiveDetail/Components/ProjectListCard.jsx

import React from "react";
import { Table } from "reactstrap";
import ProjectImage from "../../../Utils/ProjectImage";
import { Card, CardRow } from "../../../Utils/CustomCard";
import EditButton from "../../../Buttons/EditButton";
import { useProjectNavigation } from "../../../../hooks/navigationHooks";
import { createProjectModalOpenStore } from "../../../../stores/modalStore";
import CreateNewProjectButton from "../../../Buttons/CreateNewProjectButton";

function ProjectListCard({
  title,
  projectList,
  collectiveId,
  isLoading,
  isHidden,
  isEditable,
}) {
  const navigateToProject = useProjectNavigation();

  if (isHidden) {
    return null;
  }

  const onProjectClick = (projectId) => {
    navigateToProject({ collectiveId: collectiveId, projectId: projectId });
  };

  const handleCreateProjectClick = () => {
    createProjectModalOpenStore.set(true);
  };

  return (
    <Card title={title} isLoading={isLoading}>
      <CardRow>
        <Table>
          <tbody>
            {projectList?.map((project, index) => (
              <tr key={index}>
                <td
                  className="table-cell"
                  onClick={() => onProjectClick(project.id)}
                  style={{ cursor: "pointer" }}
                >
                  <ProjectImage
                    collectiveId={collectiveId}
                    projectId={project.id}
                    title={project.title}
                    image={project.project_image}
                    size={"1.8rem"}
                  />
                  <span className="text-wrap-1">{project.title}</span>
                </td>
              </tr>
            ))}
            {isEditable && (
              <tr>
                <td>
                  <div
                    className="table-cell"
                    onClick={handleCreateProjectClick}
                    style={{ cursor: "pointer" }}
                  >
                    <CreateNewProjectButton />
                    <p className="text-overflow-trim short text-accented">
                      Create Project
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </CardRow>
      <EditButton isEditable={isEditable} onClick={handleCreateProjectClick} />
    </Card>
  );
}

export default ProjectListCard;

