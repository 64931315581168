import NotificationsButton from "./NotificationsButton";
import CurrentUserProfileButton from "./CurrentUserProfileButton";
import SideBarButton from "./SideBarButton";
import MCSLogoText from "../../assets/images/logo-text.png";
import { useStore } from "@nanostores/react";
import { currentUserDefaultCollectiveIdStore } from "../../stores/currentUserStore";
import { useCollectiveNavigation } from "../../hooks/navigationHooks";
import { collectiveStore } from "../../stores/collectiveStore";

const NavBarProtected = () => {
  const navigateToCollective = useCollectiveNavigation();
  const defaultCollectiveId = useStore(currentUserDefaultCollectiveIdStore);
  const activeCollectiveId = useStore(collectiveStore)?.id;
  // conditional logic to use the active Collective id if it exists, otherwise use the default Collective id, otherwise use the MCS community Collective id
  const collectiveId = activeCollectiveId || defaultCollectiveId || 5;

  return (
    <div className="navbar">
      <div className="navbar-contents">
        <div className="navbar-left">
          <SideBarButton />
          <div
            className="logo-container"
            style={{ width: "7rem", height: "auto", cursor: "pointer" }}
            onClick={() => navigateToCollective({ collectiveId: collectiveId })}
          >
            <img src={MCSLogoText} alt="MCS logo with text" />
          </div>
        </div>
        <div className="navbar-right">
          <NotificationsButton />
          <CurrentUserProfileButton />
        </div>
      </div>
    </div>
  );
};

export default NavBarProtected;

