import React from "react";
import OtherUserProfileButton from "../Buttons/OtherUserProfileButton";
import UnreadPostIcon from "../Icons/UnreadPostIcon.jsx";
import { formatPostTimestamp } from "../Utils/formatTime.js";
import { usePostNavigation } from "../../hooks/navigationHooks";
import { markNotificationAsRead } from "../../stores/notificationsStore";
import { Notification } from "../../api";

interface Props {
  notification?: Notification;
  isLoading?: boolean;
}

export const NotificationObject: React.FC<Props> = ({
  notification,
  isLoading,
}) => {
  const navigateToPost = usePostNavigation();
  const message = notification?.message;
  const subMessage = notification?.sub_message;
  const notificationDate = formatPostTimestamp(notification?.created_at);
  const notificationReadDate = notification?.read_date;
  const notificationIsRead = notificationReadDate !== null;
  const initiator = notification?.initiator;
  const collectiveId = notification?.collective;
  const parentPostId = notification?.parent_post_id;
  const childPostId = notification?.post;

  const handleNotificationClick = () => {
    if (!notificationIsRead && notification) {
      markNotificationAsRead(notification.id);
    }
    if (collectiveId && parentPostId && childPostId) {
      navigateToPost({
        collectiveId: collectiveId,
        parentPostId: parentPostId,
        childPostId: childPostId,
      });
    }
  };

  if (isLoading) {
    return (
      <div
        className="notification-object-loading"
        style={{ marginTop: ".5rem" }}
      >
        <div
          className="notification-detail"
          style={{ display: "flex", gap: ".5rem" }}
        >
          <div
            className="loading-component"
            style={{ height: ".8rem", width: "5rem", borderRadius: ".5rem" }}
          ></div>
          <div
            className="loading-component"
            style={{ height: "1rem", width: "50%", borderRadius: ".5rem" }}
          ></div>
          <div
            className="loading-component"
            style={{ height: ".8rem", width: "80%", borderRadius: ".5rem" }}
          ></div>
        </div>
      </div>
    );
  }

  return (
    <div className="notification-object" onClick={handleNotificationClick}>
      <div>
        <OtherUserProfileButton user={initiator} size={"3rem"} />
      </div>
      <div className="notification-detail">
        <p className="sm text-muted">
          <span>{notificationDate}</span>
        </p>
        <h4>{message}</h4>
        <p className="text-muted text-break text-wrap-3">"{subMessage}"</p>
      </div>
      {!notificationIsRead && (
        <div className="notification-unread-icon">
          <UnreadPostIcon />
        </div>
      )}
    </div>
  );
};

export default NotificationObject;

