import { axiosClient, errorHandler } from "../client";
import { IListPostsRequest } from "../interfaces";
import { ApiResponse, PaginatedList } from "../generics";
import { Post } from "../models";

export const listPostsAsync = async (
  params?: IListPostsRequest,
): Promise<ApiResponse<PaginatedList<Post>>> =>
  axiosClient
    .get<ApiResponse<PaginatedList<Post>>>("/posts/", {
      params,
    })
    .then((x) => x.data)
    .catch(errorHandler);

export const retrievePostAsync = async (
  id: number,
): Promise<ApiResponse<Post>> =>
  axiosClient
    .get<ApiResponse<Post>>(`/posts/${id}/`)
    .then((x) => x.data)
    .catch(errorHandler);
