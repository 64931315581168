import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useStore } from "@nanostores/react";
import {
  getNotifications,
  items,
  unreadCount,
} from "../../stores/notificationsStore";
import OtherUserProfileButton from "../Buttons/OtherUserProfileButton";
import { formatPostTimestamp } from "../Utils/formatTime";
import { Notification } from "../../api";
import { usePostNavigation } from "../../hooks/navigationHooks";
import { markNotificationAsRead } from "../../stores/notificationsStore";
import UnreadPostIcon from "../Icons/UnreadPostIcon";

const NotificationsButton = () => {
  const unreadNotificationsCount = useStore(unreadCount);
  const notifications = useStore(items);
  const navigateToPost = usePostNavigation();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
    if (!dropdownOpen) {
      // if dropdown is closed, get notfications when opened
      getNotifications();
    }
  };

  const handleNotificationClick = (notification: Notification) => {
    const notificationReadDate = notification.read_date;
    const notificationIsRead = notificationReadDate !== null;
    if (!notificationIsRead && notification) {
      markNotificationAsRead(notification.id);
    }
    navigateToPost({
      collectiveId: notification.collective,
      parentPostId: notification.parent_post_id,
      childPostId: notification.post,
    });
  };

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle tag="div" className="btn-circle-lg">
        <div>
          <span className="btn-circle-lg-icon">
            <i className="bi bi-bell-fill"> </i>
          </span>
          {unreadNotificationsCount > 0 && (
            <span className="notification-bubble">
              {unreadNotificationsCount}
            </span>
          )}
        </div>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem tag={Link} to={`/studio/notifications`}>
          <div>
            <h2>Notifications</h2>
          </div>
        </DropdownItem>
        {notifications.slice(0, 8).map((notification, index) => (
          <DropdownItem
            key={index}
            onClick={() => handleNotificationClick(notification)}
          >
            <div className="notification-dropdown-object">
              <OtherUserProfileButton
                user={notification.initiator}
                size={"2.5rem"}
              />
              <div className="notification-dropdown-object-detail">
                <p className="sm text-muted">
                  {formatPostTimestamp(notification?.created_at)}
                </p>
                <p className="md bold text-break text-overflow-trim ">
                  {notification.message}
                </p>
                <p className="sm text-muted text-break text-overflow-trim ">
                  {notification.sub_message}
                </p>
              </div>
              {!notification.read_date && (
                <div className="notification-unread-icon">
                  <UnreadPostIcon />
                </div>
              )}
            </div>
          </DropdownItem>
        ))}
        <DropdownItem tag={Link} to={`/studio/notifications`}>
          <p className="text-center">View all notifications</p>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default NotificationsButton;

