// src/pages/CollectivePage.jsx

import { useEffect } from "react";
import SideBar from "../components/SideBar/SideBar";
import DetailArea from "../components/DetailArea/DetailArea";
import { setActivePostId, fetchParentTree } from "../stores/postDetailStore";
import { getNotifications } from "../stores/notificationsStore";
import { currentUserDefaultCollectiveIdStore } from "../stores/currentUserStore";
import {
  useCollectiveNavigation,
  useURLParams,
} from "../hooks/navigationHooks";
import {
  collectiveStore,
  getCollective,
  clearCollectiveStore,
} from "../stores/collectiveStore";

const CollectivePage = () => {
  const navigateToCollective = useCollectiveNavigation();
  const { collectiveId, projectId, postId, childPostId } = useURLParams();
  const defaultCollectiveId = currentUserDefaultCollectiveIdStore.get();

  useEffect(() => {
    getNotifications(); // update the notifications
    // Check if the current path does not contain a Collective id
    if (!collectiveId) {
      navigateToCollective({
        collectiveId: defaultCollectiveId || 5, // redirect to default collective or MCS community (id 5)
        replace: true,
      });
    } else {
      // once the above logic has been handled the collectiveId should then be valid within the url
      // this should provide a valid collectiveId to fetch the Collective details
      // therefore, Collective details will only be fetched once as the above logic redirects to a valid url before fetch is called
      // think of the above code as a safety net before it gets to this point in the script

      // if the collectiveId is different from the one in the store, clear the store
      // make sure that collectiveId is a number
      if (collectiveId !== collectiveStore.get()?.id) {
        clearCollectiveStore();
      }

      // Fetch Collective details with the post ID and set active post
      // Use the projectId from the url if it exists
      // backend will handle the case where projectId is not provided
      getCollective({ collectiveId: collectiveId, projectId: projectId }).then(
        () => {
          // if post id is in the url then set active post
          if (postId) {
            setActivePostId(postId);
            // if child post id is in the url then fetch parent tree
            // fetch parent tree will also set the ids that should be rendered
            if (childPostId) {
              fetchParentTree(childPostId);
            }
          }
        },
      );
    }
  }, [
    collectiveId,
    projectId,
    postId,
    childPostId,
    defaultCollectiveId,
    navigateToCollective,
  ]);

  return (
    <div className="protected-page-layout">
      <SideBar />
      <DetailArea />
    </div>
  );
};

export default CollectivePage;

