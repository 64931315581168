//src/components/DetailArea/ProjectDetail/ProjectDetail.jsx

import { CardSection } from "../../Utils/CustomCard";
import { useStore } from "@nanostores/react";
import HeaderCard from "../CollectiveDetail/Components/HeaderCard";
import ProjectListCard from "../CollectiveDetail/Components/ProjectListCard";
import MembersCard from "../CollectiveDetail/Components/MembersCard";
import PendingInvitesCard from "../CollectiveDetail/Components/PendingInvitesCard";
import { userIsAdminOwnerOrMember } from "../../Utils/permissionsUtils";
import { editProjectModalOpenStore } from "../../../stores/modalStore";
import {
  collectiveStore,
  collectiveLoadingStore,
} from "../../../stores/collectiveStore";

function ProjectDetail() {
  const collective = useStore(collectiveStore);
  const isLoading = useStore(collectiveLoadingStore);
  const currentUserRole = collective?.user_role;
  const userCanEdit = userIsAdminOwnerOrMember(currentUserRole);
  const projectList = collective?.project_list;
  const projectDetails = collective?.project;

  const handleProjectClick = () => {
    editProjectModalOpenStore.set(true);
  };

  return (
    <div className="detail-area">
      <CardSection>
        <HeaderCard
          headerTitle={projectDetails?.title}
          headerSubtitle={"Project by " + collective?.title}
          descriptionTitle={"Project Description"}
          description={projectDetails?.description}
          image={projectDetails?.project_image}
          isLoading={isLoading}
          isEditable={userCanEdit}
          onEditClick={handleProjectClick}
        />
        <ProjectListCard
          title={"Collective Projects"}
          projectList={projectList}
          collectiveId={collective?.id}
          isLoading={isLoading}
          isHidden={!userCanEdit && !projectList?.length > 0}
          isEditable={userCanEdit}
        />
        <MembersCard
          title={"Collective Members"}
          members={collective?.members}
          isLoading={isLoading}
          isEditable={userCanEdit}
        />
        <PendingInvitesCard
          title={"Pending Collective Invitations"}
          pendingInvites={collective?.pending_invites}
          isLoading={isLoading}
          isEditable={userCanEdit}
          isHidden={!userCanEdit}
        />
      </CardSection>
    </div>
  );
}

export default ProjectDetail;

