//src/stores/modalStore.js
import { atom } from "nanostores";

// collective modals
export const createCollectiveModalOpenStore = atom(false);
export const editCollectiveModalOpenStore = atom(false);

// invite user modal
export const inviteUserModalOpenStore = atom(false);

// parent post modal
export const createParentPostModalOpenStore = atom(false);

// project modal
export const createProjectModalOpenStore = atom(false);
export const editProjectModalOpenStore = atom(false);

// user detail modal
export const viewUserDetailModalOpenStore = atom(false);
export const userDetailModalUserIDStore = atom(null);

// edit collective members modal
export const editCollectiveMembersModalOpenStore = atom(false);

// edit profile modal
export const editUserProfileModalOpenStore = atom(false);

// loading state for modals
export const isModalLoadingStore = atom(false);

export function closeModals() {
  createCollectiveModalOpenStore.set(false);
  editCollectiveModalOpenStore.set(false);
  inviteUserModalOpenStore.set(false);
  createParentPostModalOpenStore.set(false);
  createProjectModalOpenStore.set(false);
  editProjectModalOpenStore.set(false);
  viewUserDetailModalOpenStore.set(false);
  userDetailModalUserIDStore.set(null);
  editCollectiveMembersModalOpenStore.set(false);
  editUserProfileModalOpenStore.set(false);
  isModalLoadingStore.set(false);
}

export function openUserDetailModal(userId) {
  viewUserDetailModalOpenStore.set(true);
  userDetailModalUserIDStore.set(userId);
}

