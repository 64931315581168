import { axiosClient, errorHandler } from "../client";
import { IRetrieveCollectiveRequest } from "../interfaces";
import { ApiResponse } from "../generics";
import { Collective } from "../models";

export const retrieveCollectiveAsync = async (
  id: number,
  params?: IRetrieveCollectiveRequest,
): Promise<ApiResponse<Collective>> =>
  axiosClient
    .get<ApiResponse<Collective>>(`/collectives/${id}/retrieve/`, {
      params,
    })
    .then((x) => x.data)
    .catch(errorHandler);
