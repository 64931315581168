// src/components/Buttons/PlayButton.jsx

import { useState } from "react";
import LoadingIndicatorCircle from "../Utils/LoadingIndicatorCircle.jsx";
import { useStore } from "@nanostores/react";
import { patchProjectPosts } from "../../stores/postDetailStore.js";
import { collectiveStore } from "../../stores/collectiveStore";

const AddToProjectButton = ({ postDetails }) => {
  const activeProjectId = useStore(collectiveStore)?.project?.id;
  const postId = postDetails?.id;
  const relatedProjects = postDetails?.related_projects || [];
  const isPostInProject = relatedProjects.includes(activeProjectId);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddToProject = async () => {
    setIsLoading(true);
    const postsToAdd = [postId];
    const postsToRemove = [];
    await patchProjectPosts(activeProjectId, postsToAdd, postsToRemove);
    setIsLoading(false);
  };

  const handleRemoveFromProject = async () => {
    setIsLoading(true);
    const postsToAdd = [];
    const postsToRemove = [postId];
    await patchProjectPosts(activeProjectId, postsToAdd, postsToRemove);
    setIsLoading(false);
  };

  // if loading, show loading indicator
  if (isLoading) {
    return (
      <div className="btn-circle-md">
        <LoadingIndicatorCircle />
      </div>
    );
  }

  if (isPostInProject) {
    return (
      <div
        className="btn-circle-md btn-remove"
        onClick={handleRemoveFromProject}
      >
        <div>
          <span className="btn-circle-lg-icon">
            <i className="bi bi-dash"></i>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="btn-circle-md btn-add" onClick={handleAddToProject}>
      <div>
        <span className="btn-circle-lg-icon">
          <i className="bi bi-plus"></i>
        </span>
      </div>
    </div>
  );
};

export default AddToProjectButton;

