import { axiosClient, errorHandler } from "../client";
import { IListNotificationsRequest } from "../interfaces";
import { ApiResponse, PaginatedNotificationsList } from "../generics";
import { Notification, PK } from "../models";

export const listNotificationsAsync = async (
  params?: IListNotificationsRequest,
): Promise<ApiResponse<PaginatedNotificationsList<Notification>>> =>
  axiosClient
    .get<ApiResponse<PaginatedNotificationsList<Notification>>>(
      "/notifications/",
      {
        params,
      },
    )
    .then((x) => x.data)
    .catch(errorHandler);

export const markNotificationAsReadAsync = async (
  id: PK,
): Promise<ApiResponse<Notification>> =>
  axiosClient
    .patch<ApiResponse<Notification>>(`/notifications/${id}/mark_as_read/`)
    .then((x) => x.data)
    .catch(errorHandler);

export const markAllNotificationsAsReadAsync = async (): Promise<ApiResponse> =>
  axiosClient
    .patch<ApiResponse>("/notifications/mark_all_as_read/")
    .then((x) => x.data)
    .catch(errorHandler);

export const markAllNotificationsAsUnreadAsync =
  async (): Promise<ApiResponse> =>
    axiosClient
      .patch<ApiResponse>("/notifications/mark_all_as_unread/")
      .then((x) => x.data)
      .catch(errorHandler);
