// src/components/Forms/FormFields/RichTextEditor.jsx

import React, { useState, useEffect, forwardRef } from "react";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  BeautifulMentionsPlugin,
  BeautifulMentionNode,
} from "lexical-beautiful-mentions";
import { $getRoot } from "lexical";

// get values from store
import { useStore } from "@nanostores/react";
import { collectiveStore } from "../../../stores/collectiveStore";
import { collectiveFullPostList } from "../../../stores/postStore";

function onError(error) {
  console.error(error);
}

// When the editor changes, you can get notified via the OnChangePlugin!
function OnChangePlugin({ onChange }) {
  // Access the editor through the LexicalComposerContext
  const [editor] = useLexicalComposerContext();
  // Wrap our listener in useEffect to handle the teardown and avoid stale references.
  useEffect(() => {
    // most listeners return a teardown function that can be called to clean them up.
    return editor.registerUpdateListener(({ editorState }) => {
      // call onChange here to pass the latest state up to the parent.
      onChange(editorState);
    });
  }, [editor, onChange]);
  return null;
}

// Custom plugin to reset the editor state when the initial JSON is null
function EditorResetPlugin({ initialJson }) {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    if (initialJson === null) {
      editor.update(() => {
        const root = $getRoot();
        root.clear();
      });
    }
  }, [initialJson, editor]);

  return null;
}

// STYLES
// We use forwardRef to create a custom component for the mention menu items
// This allows us to pass the ref from the BeautifulMentionsPlugin to our custom component
// By forwarding the ref, we can access the underlying DOM element and apply custom styles
// Without forwardRef, we wouldn't be able to style the mention menu items properly
// For example, targeting the typeahead menu id with CSS was not practical
const CustomMentionMenu = (props) => {
  return (
    <div
      style={{
        backgroundColor: "var(--button-background)",
        paddingTop: ".5rem",
        paddingBottom: ".5rem",
        minWidth: "18rem",
        borderRadius: ".5rem",
        position: "absolute",
        bottom: "100%",
        marginBottom: "1.5rem",
        zIndex: 1100, // Ensure the menu is above the bootstrap modal which has a z-index of 1050
      }}
    >
      {props.children}
    </div>
  );
};

const CustomMentionMenuItem = forwardRef((props, ref) => {
  const { item, selected, onMouseEnter, onMouseLeave } = props;
  return (
    <div
      ref={ref}
      style={{
        backgroundColor: selected
          ? "var(--button-background-hover)"
          : "transparent",
        paddingLeft: ".8rem",
        paddingRight: ".8rem",
        paddingTop: ".3rem",
        paddingBottom: ".3rem",
        cursor: "pointer",
      }}
      onMouseEnter={() => onMouseEnter(item)}
      onMouseLeave={onMouseLeave}
    >
      {item.value}
    </div>
  );
});

function RichTextEditor({
  initialJson,
  initialText,
  setJson,
  setText,
  setMentionedUserIds,
  setMentionedPostIds,
  placeholderValue,
}) {
  const collectiveMembers = useStore(collectiveStore)?.members;
  const collectivePosts = useStore(collectiveFullPostList);
  const [, setEditorState] = useState();

  // on change function to handle the editor state
  function onChange(editorState) {
    // Call toJSON on the EditorState object, which produces a serialization safe string
    const editorStateJSON = editorState.toJSON();
    // However, we still have a JavaScript object, so we need to convert it to an actual string with JSON.stringify
    const editorStateString = JSON.stringify(editorStateJSON);
    setEditorState(editorStateString);
    setJson(editorStateString);

    // extract the mention nodes from the editor state
    const mentionNodes = JSON.parse(editorStateString)
      .root.children.flatMap((paragraph) => paragraph.children)
      .filter((node) => node.type === "beautifulMention");

    // extract the user ids from the mention nodes
    const userIds = new Set(
      mentionNodes
        .filter((node) => node.data.datatype === "user")
        .map((node) => node.data.id),
    );

    // extract the post ids from the mention nodes
    const postIds = new Set(
      mentionNodes
        .filter((node) => node.data.datatype === "post")
        .map((node) => node.data.id),
    );

    // set the mentioned user and post ids in the parent component
    setMentionedUserIds(Array.from(userIds));
    setMentionedPostIds(Array.from(postIds));

    // Call the setText callback with the text content
    const text = editorState.read(() => $getRoot().getTextContent());
    setText(text);
  }

  // Define the initial configuration for the LexicalComposer
  const initialConfig = {
    namespace: "MyEditor",
    onError,
    nodes: [BeautifulMentionNode],
  };

  // Define the mention items for the BeautifulMentionsPlugin
  const mentionItems = {
    "@": collectiveMembers
      .filter((member) => member.user.username !== null)
      .map((member) => ({
        value: member.user.username,
        id: member.user.id,
        datatype: "user",
      })),
    "#": collectivePosts
      .filter((post) => post.title !== null)
      .map((post) => ({
        value: post.title,
        id: post.id,
        datatype: "post",
      })),
  };

  return (
    <div className="rte-container">
      <LexicalComposer initialConfig={initialConfig}>
        <RichTextPlugin
          contentEditable={<ContentEditable className="rte-content" />}
          placeholder={
            <div className="rte-placeholder">{placeholderValue}</div>
          }
          ErrorBoundary={LexicalErrorBoundary}
        />
        <EditorResetPlugin initialJson={initialJson} />
        <OnChangePlugin onChange={onChange} />
        <BeautifulMentionsPlugin
          items={mentionItems}
          menuComponent={CustomMentionMenu}
          menuItemComponent={CustomMentionMenuItem}
          creatable={false} // hide the menu item that allows users to create new mentions
        />
        <HistoryPlugin />
        <AutoFocusPlugin />
      </LexicalComposer>
    </div>
  );
}

export default RichTextEditor;

