import React, { useEffect } from "react";
import NotificationObject from "./NotificationObject";
import OptionsDropdown from "./OptionsDropdown";
import { useStore } from "@nanostores/react";
import {
  getNotifications,
  getMoreNotifications,
  items,
  hasMore,
  count,
} from "../../stores/notificationsStore";
import { OnEndReachedTrigger } from "../Utils/OnEndReachedTrigger";

const LoadingComponents = () => {
  return (
    <>
      <NotificationObject isLoading={true} />
      <NotificationObject isLoading={true} />
      <NotificationObject isLoading={true} />
      <NotificationObject isLoading={true} />
      <NotificationObject isLoading={true} />
    </>
  );
};

const InnerNotificationsList = () => {
  const hasMoreNotifications = useStore(hasMore);
  const notifications = useStore(items);
  const notificationCount = useStore(count);

  if (notificationCount === 0 && hasMoreNotifications) {
    return (
      <>
        <LoadingComponents />
        <LoadingComponents />
      </>
    );
  }

  return (
    <>
      {notifications.map((notification, index) => (
        <NotificationObject notification={notification} key={index} />
      ))}
      {hasMoreNotifications && (
        <>
          <OnEndReachedTrigger
            fetchFunction={getMoreNotifications}
            shouldFetch={hasMoreNotifications}
          />
          <LoadingComponents />
        </>
      )}
    </>
  );
};

export const NotificationsList = React.memo(InnerNotificationsList);

const InnerNotificationsContainer = () => {
  useEffect(() => {
    getNotifications();
  }, []);

  return (
    <div className="notifications-container">
      <div>
        <h1>Notifications</h1>
      </div>
      <OptionsDropdown />
      <NotificationsList />
    </div>
  );
};

export const NotificationsContainer = React.memo(InnerNotificationsContainer);

