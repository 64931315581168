import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

interface Props {
  fetchFunction: () => void;
  shouldFetch: boolean;
}

export const OnEndReachedTrigger: React.FC<Props> = ({
  fetchFunction,
  shouldFetch,
}) => {
  const { ref, inView } = useInView({});

  useEffect(() => {
    if (inView && shouldFetch) {
      fetchFunction();
    }
  }, [inView, shouldFetch, fetchFunction]);

  return <div ref={ref}></div>;
};

