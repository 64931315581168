import { useState, useRef, useEffect } from "react";

interface AccordionProps {
  children: React.ReactNode;
  header: React.ReactNode;
  disabledHeader?: React.ReactNode;
  isOpen?: boolean;
  isDisabled?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
}

const Accordion: React.FC<AccordionProps> = ({
  children,
  header,
  disabledHeader = header,
  isOpen = false,
  isDisabled = false,
  setIsOpen,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpen);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [maxHeight, setMaxHeight] = useState("0px");

  const handleAccordionClick = () => {
    if (!isDisabled) {
      setIsAccordionOpen(!isAccordionOpen);
    }
    if (setIsOpen) {
      setIsOpen(!isAccordionOpen);
    }
  };

  // Update isAccordionOpen when isOpen changes
  useEffect(() => {
    setIsAccordionOpen(isOpen);
  }, [isOpen]);

  // Update maxHeight when isOpen or children changes
  useEffect(() => {
    if (isAccordionOpen && contentRef.current) {
      setMaxHeight(`${contentRef.current.scrollHeight}px`);
    } else {
      setMaxHeight("0px");
    }
  }, [isAccordionOpen, children]);

  const displayHeader = isAccordionOpen ? header : disabledHeader;

  return (
    <div className="accordion text-prevent-selecting">
      <div
        className={`accordion-header ${isAccordionOpen ? "active" : ""}`}
        onClick={handleAccordionClick}
      >
        {displayHeader}
        {isDisabled ? null : (
          <i
            className={`bi bi-chevron-right accordion-chevron-icon ${
              isAccordionOpen ? "rotate" : ""
            }`}
          ></i>
        )}
      </div>
      <div
        className={`accordion-content ${!isAccordionOpen ? "closed" : ""}`}
        style={{ maxHeight }}
        ref={contentRef}
      >
        {children}
      </div>
    </div>
  );
};

export { Accordion };

