import { useNavigate, useLocation, useParams } from "react-router-dom";

interface ICollectiveNavigation {
  collectiveId: number;
  replace?: boolean;
}

interface IProjectNavigation {
  collectiveId: number;
  projectId: number;
  replace?: boolean;
}

interface IPostNavigation {
  collectiveId: number;
  parentPostId: number;
  childPostId: number;
  replace?: boolean;
}

interface ISetUrlParams {
  collectiveId?: number;
  projectId?: number;
  postId?: number;
  childPostId?: number;
}

export function useCollectiveNavigation() {
  const navigate = useNavigate();

  return ({ collectiveId, replace = false }: ICollectiveNavigation) => {
    navigate(`/studio/collective/${collectiveId}`, { replace });
  };
}

export function useProjectNavigation() {
  const navigate = useNavigate();

  return ({ collectiveId, projectId, replace = false }: IProjectNavigation) => {
    navigate(`/studio/collective/${collectiveId}?projectId=${projectId}`, {
      replace,
    });
  };
}

export function usePostNavigation() {
  const navigate = useNavigate();

  return ({
    collectiveId,
    parentPostId,
    childPostId,
    replace = false,
  }: IPostNavigation) => {
    const collectiveUrlPart = `/studio/collective/${collectiveId}`;
    const postUrlPart = parentPostId ? `?&postId=${parentPostId}` : "";
    const childPostUrlPart = childPostId ? `&childPostId=${childPostId}` : "";

    navigate(`${collectiveUrlPart}${postUrlPart}${childPostUrlPart}`, {
      replace,
    });
  };
}

export const useURLParams = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  return {
    collectiveId: Number(useParams().collectiveId),
    projectId: Number(searchParams.get("projectId")),
    postId: Number(searchParams.get("postId")),
    childPostId: Number(searchParams.get("childPostId")),
  };
};

// utility function to set the URL params without refreshing the page
export function setURLParams({
  collectiveId,
  projectId,
  postId,
  childPostId,
}: ISetUrlParams) {
  const collectiveUrlPart = collectiveId ? `/collective/${collectiveId}?` : "";
  const projectUrlPart = projectId ? `&projectId=${projectId}` : "";
  const postUrlPart = postId ? `&postId=${postId}` : "";
  const childPostUrlPart = childPostId ? `&childPostId${childPostId}` : "";

  const newUrl = `/studio${collectiveUrlPart}${projectUrlPart}${postUrlPart}${childPostUrlPart}`;

  // Use history.pushState() to change the URL without refreshing the page
  window.history.pushState({ path: newUrl }, "", newUrl);
}

