import { atom, map, computed } from "nanostores";
import { listPostsAsync, retrievePostAsync, PK } from "../api";
import { Post } from "../api/models";

// CONFIG

interface ListConfig {
  itemIds: PK[];
  initialItemIds: PK[];
  totalItems: number;
  currentPage: number;
  nextPage: number;
  hasMore: boolean;
  collective: PK | null;
  project: PK | null;
}

interface IGetMorePosts {
  collective?: PK;
  project?: PK;
}

interface IGetPosts extends IGetMorePosts {
  fetchMore?: boolean;
}

// utility function to create a base list config to reduce redundancy and allow custom filters
const baseListConfig = (): ListConfig => ({
  itemIds: [],
  initialItemIds: [],
  totalItems: 0,
  currentPage: 1,
  nextPage: 1,
  hasMore: true,
  collective: null,
  project: null,
});

// STORE ITEMS

export const items = map<Record<PK, Post>>({});
export const collectivePostList = atom<ListConfig>(baseListConfig());
export const projectPostList = atom<ListConfig>(baseListConfig());

const pageSize = 20;
let queue = Promise.resolve(); // Queue initialized as a resolved Promise

// utility function to get a post by id
export function getPost(id: PK) {
  return computed(items, (allItems) => {
    if (!allItems[id]) {
      retrievePostAsync(id).then((response) => {
        if (response.success) {
          items.setKey(id, response.data);
        }
      });
    }
    return allItems[id];
  });
}

export function clearCollectivePosts() {
  // items.set({}); // don't clear all items as new items will just be replaced anyway and improves performance
  collectivePostList.set(baseListConfig());
  // TODO: ensure this clears all other states
}

export function clearProjectPosts() {
  // items.set({}); // don't clear all items as new items will just be replaced anyway and improves performance
  projectPostList.set(baseListConfig());
  // TODO: ensure this clears all other states
}

export function getPosts({
  collective,
  project,
  fetchMore = false, // will default to false on the first call
}: IGetPosts) {
  // Chain each call to `queue`, ensuring they execute sequentially
  queue = queue.then(() =>
    fetchPosts({
      collective: collective,
      project: project,
      fetchMore: fetchMore,
    }),
  );
}

// utility function to get the details of the store
export const collectiveFullPostList = computed(
  [collectivePostList, items],
  (listConfig, allItems): Post[] => {
    // Map the item IDs in collectivePostList to their corresponding Posts in items
    return listConfig.itemIds
      .map((id: PK) => allItems[id]) // Retrieve each Post by ID
      .filter((item): item is Post => item !== undefined); // Filter out any undefined entries
  },
);

function areArraysEqual(arr1: number[], arr2: number[]): boolean {
  if (arr1.length !== arr2.length) return false;
  return arr1.every(
    (item, index) => JSON.stringify(item) === JSON.stringify(arr2[index]),
  );
}

// utility function to get the correct list based on the collective or project
function getList({ collective, project }: IGetMorePosts) {
  return project ? projectPostList : collective ? collectivePostList : null;
}

export async function fetchPosts({
  collective,
  project,
  fetchMore = false, // will default to false on the first call
}: IGetPosts) {
  const list = getList({ collective, project });

  if (!list) {
    return;
  }

  const response = await listPostsAsync({
    collective: collective,
    ...(project && { related_projects: project }), // only add if project exists
    parent_post_only: true,
    page: fetchMore ? list.get().nextPage : 1,
    page_size: pageSize,
  });
  if (response.success) {
    const results = response.data.results;
    const resultIds = results.map((item) => item.id);
    const count = response.data.count;
    const next = response.data.next;
    // don't update the store if the data is the same
    // this avoids the double loading effect when initial load may rerender
    if (!fetchMore && areArraysEqual(resultIds, list.get().initialItemIds)) {
      return;
      // otherwise, if initial fetch, set the results which will overwrite the store
    } else if (!fetchMore) {
      list.set({
        ...list.get(),
        itemIds: resultIds,
        initialItemIds: resultIds, // and update the initial items given were fetching new list data
        totalItems: count,
        hasMore: next !== null,
        currentPage: 1,
        nextPage: 2,
        collective: collective || null,
        project: project || null,
      });
      for (const item of results) {
        items.setKey(item.id, item); // update the items store with the new data or overwrite
      }
      // lastly, if fetch more, append the results to the existing store
    } else if (fetchMore) {
      const currentItemIds = new Set(list.get().itemIds); // Convert existing IDs to a Set for fast lookups
      const newIds = resultIds.filter((id) => !currentItemIds.has(id)); // Filter only new IDs
      // Only update if there are new IDs to add
      if (newIds.length > 0) {
        list.set({
          ...list.get(),
          // Append only new IDs
          itemIds: [...list.get().itemIds, ...newIds],
          totalItems: count,
          hasMore: next !== null,
          currentPage: list.get().currentPage + 1,
          nextPage: list.get().currentPage + 2,
          collective: collective || null,
          project: project || null,
        });
      }
      for (const item of results) {
        items.setKey(item.id, item); // update the items store with the new data or overwrite
      }
    }
  }
}

export async function getMorePosts({ collective, project }: IGetMorePosts) {
  const list = getList({ collective, project });

  if (!list || !list.get().hasMore) {
    return;
  }
  getPosts({ collective, project, fetchMore: true });
}

